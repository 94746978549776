<template>
  <v-container
    class="background"
    fluid
    fill-height
  >
    <v-row class="left-right-container">
      <v-col class="left-area">
        <map-component
          mode="task"
        />
      </v-col>
      <v-col class="right-area">
        <template v-if="mapInfo!==null">
          <router-view
            :locations="locations"
            :map-info="mapInfo"
          />
        </template>
        <template v-if="isError">
          <v-row>
            <img
              src="../../assets/image/icon/back.png"
              alt="뒤로가기"
              type="button"
              @click="$router.replace('/config')"
            >
            <span class="back">
              &nbsp;뒤로
            </span>
          </v-row>
        </template>
      </v-col>
      <default-header/>
    </v-row>
  </v-container>
</template>

<script>
import MapComponent from '@/components/map/map.vue';
import DefaultHeader from '@/components/header/default_header.vue';

export default {
  name: 'Task',
  components: {
    MapComponent,
    DefaultHeader,
  },
  data() {
    return {
      locations: [],
      mapInfo: null,
      isError: false,
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      return this.fetchLocations()
        .then(this.fetchMapInfo)
        .catch(err => {
          this.isError = true;
          throw Error(err);
        });
    },
    fetchLocations() {
      return this.$axios.get('/location')
        .then(res => {
          if (res.status === 200) {
            this.locations = res.data;
          }
        });
    },
    fetchMapInfo() {
      return this.$axios.get('/info')
        .then(res => {
          if (res.status === 200) {
            this.mapInfo = res.data;
          }
        });
    },
  }
}
</script>

<style scoped>

.background > .left-right-container {
  height: 100vh;
}

.background > .left-right-container > .right-area > .btn-area {
  height: 100vh;
  overflow: auto;
  margin: 10px;
}

.background > .left-right-container > .left-area {
  padding: 5px;
}

.background {
  overflow: hidden;
  background-color: #393939;
  word-break: keep-all;
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
}

.left-right-container {
  height: 100vh;
}

.back {
  color: #ffffff;
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 60px;
}

@media (max-width: 1700px) {
  .row img {
    width: 30px;
    height: 30px
  }

  .back {
    color: #ffffff;
    font-family: sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
  }
}

</style>
